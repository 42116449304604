import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';
import { UserDto } from '../../generated/rest';
import './ProfileView.scss';
const defaultImage = '/assets/images/default-user-icon.png';

const ProfileView = ({ user }: { user: UserDto }) => {
    const [url, setUrl] = useState(user.pictureURL || defaultImage);
    const onError = () => setUrl(defaultImage);
    return (
        <div>
            <Image onError={onError} src={url} className="picture" centered />
            <div className="textual-content">
                <span className="label">Curator Name</span>
                <p className="name">{user.name}</p>
                <span className="label">Contact Information</span>
                <p className="email">{user.email}</p>
            </div>
            <div>
                <span className="label">Background</span>
                <p className="background">{user.background || 'Background is empty'}</p>
            </div>
        </div>
    );
};
export default ProfileView;
