import { Header, Image } from 'semantic-ui-react';
import './Footer.scss';

const Footer = () => (
    <div className="footer">
        <Header className="title">Thank you for visiting us!</Header>
        <Image src={'/assets/images/logo-sift.png'} className="logo" centered />
        <Header className="contact">Contact Us</Header>
        <span className="email">founders@siftonline.com</span>
    </div>
);

export default Footer;
