import { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Footer from '../../components/footer/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { Status } from '../../types/Status';
import { TopicDto } from '../../generated/rest';
import './About.scss';
import { ActiveItem } from '../../components/headline/Headline';
import ClusterHeadline from '../../components/headline/ClusterHeadline';
import { fetchClusterData } from '../../slices/clusterSlice';
import { fetchClusterTopicsData } from '../../slices/topicsSlice';
import ProfileView from '../profile/ProfileView';
import TopicCard from '../../components/topic-card/TopicCard';
import { orderBy } from 'lodash';

const goalDescripiton = `
    Our goal is simple. We are here to bring you high quality, human
    curated content on various topics. We sift through 1000s of articles,
    videos, blogs, tweets, books and more everyday with one goal in
    mind, bringing you the best information possible.
`;

const defaultTopicDescription = `
    Many people also have their own barometers for what makes a
    cute dog. books and more everyday with one goal in mind,
    bringing you the best information possible. books and more
    everyday with one goal in mind, bringing you the best
    information possible.
`;

const About = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const clusterId = parseInt(params.clusterId || '');

    const { clusterStatus, cluster } = useAppSelector((state) => state.cluster);
    const { topicsStatus, topics } = useAppSelector((state) => state.topics);

    const handleTopicPageClick = (id: number, topicId: number) => {
        navigate(`/clusters/${id}/topics/${topicId}`);
    };

    useEffect(() => {
        dispatch(fetchClusterTopicsData(clusterId));
        dispatch(fetchClusterData(clusterId));
    }, [clusterId, dispatch]);

    const renderTopic = (topic: TopicDto) => {
        return (
            <TopicCard
                name={topic.title}
                description={topic.description || defaultTopicDescription}
                image={topic.pictureURL || '/assets/images/topic-card.png'}
                onButtonClick={() => handleTopicPageClick(topic.clusterId, topic.id)}
            />
        );
    };

    const renderCluster = () => {
        return (
            <>
                {cluster && (
                    <>
                        <h3 className="page-title">{cluster.name}</h3>
                        <p className="cluster-goal">{cluster?.description || goalDescripiton}</p>
                    </>
                )}
                {!cluster && <Loader active inline="centered" className="my-5" />}
            </>
        );
    };

    const renderTopics = () =>
        topicsStatus === Status.LOADING || clusterStatus === Status.LOADING ? (
            <Loader active inline="centered" className="my-5" />
        ) : (
            <div className="row topics">
                {topics &&
                    orderBy(topics, 'position').map((topic) => (
                        <div key={topic.id} className="col-12 mt-4">
                            {renderTopic(topic)}
                        </div>
                    ))}
                {(!topics || topics.length == 0) && (
                    <div className="empty-cluster">
                        List of topics for cluster {cluster ? cluster.name : clusterId} is empty.
                    </div>
                )}
            </div>
        );

    const renderCurators = () => {
        return cluster && cluster.users ? (
            cluster.users.map((user) => <ProfileView key={user.id} user={user} />)
        ) : (
            <></>
        );
    };

    return (
        <div className="about-page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ClusterHeadline clusterId={clusterId} active={ActiveItem.ABOUT} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">{renderCluster()}</div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 className="page-sub-title mt-3">Sub Topics</h3>
                    </div>
                </div>
                {renderTopics()}
                <div className="curator-display row">{renderCurators()}</div>
                <div className="row">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default About;
