import { orderBy } from 'lodash';
import { Card, Header, Item } from 'semantic-ui-react';
import { ContentDto, SectionDto } from '../../generated/rest';
import './Segment.scss';

type SegmentProps = {
    section: SectionDto;
};

const Segment = (props: SegmentProps) => {
    const generateUrl = (url: string) => {
        if (url) {
            return url.indexOf('http') == 0 ? url : `https://${url}`;
        }
        return '';
    };
    const createContentComponent = (content: ContentDto) => {
        if (content.type === 'LINK' && content.link) {
            return (
                <Item key={content.id}>
                    <Item.Content verticalAlign="middle">
                        <Header as="h4" className="segment-item">
                            <a target="__blank" href={generateUrl(content.link.link)}>
                                {content.link.description}
                            </a>
                        </Header>
                    </Item.Content>
                </Item>
            );
        }

        return null;
    };

    const { section } = props;
    const listRenderer = orderBy(section.contents, 'position').map((content: ContentDto) =>
        createContentComponent(content)
    );

    return (
        <div className="segment">
            <Card centered raised className="segment-card">
                <Header textAlign="center" className="segment-title">
                    {section.title}
                </Header>
                {listRenderer}
            </Card>
        </div>
    );
};

export default Segment;
