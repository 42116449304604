import { Image, Menu } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { ActiveItem } from './Headline';
import './Headline.scss';

const ClusterHeadline = ({ active, clusterId }: { active: ActiveItem; clusterId?: number }) => {
    const navigate = useNavigate();
    return (
        <Menu secondary>
            <Link to="/" className="head-line">
                <Image className="logo" src={'/assets/images/logo-sift.png'} />
            </Link>
            <Menu.Menu position="right">
                <Menu.Item
                    name="About"
                    className="menu-item"
                    link={true}
                    active={active === ActiveItem.ABOUT}
                    onClick={() => navigate(`/about/${clusterId}`)}
                />
                <Menu.Item
                    name="Archive"
                    className="menu-item"
                    link={true}
                    active={active === ActiveItem.ARCHIVE}
                    onClick={() => navigate(`/archive/${clusterId}`)}
                />
            </Menu.Menu>
        </Menu>
    );
};

export default ClusterHeadline;
