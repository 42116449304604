import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DropdownItemProps, DropdownProps, Form, Input, Label, Select } from 'semantic-ui-react';
import {
    contentService,
    sectionService,
    topicService,
    linkTypeService,
    clusterService,
} from '../../api/http.service';
import Footer from '../../components/footer/Footer';
import { ActiveItem } from '../../components/headline/Headline';
import ClusterHeadline from '../../components/headline/ClusterHeadline';
import {
    ArchivedContentRequest,
    ContentDto,
    LinkTypeDto,
    SectionDto,
    TopicDto,
} from '../../generated/rest';
import './archive.scss';

const Archive = () => {
    const [request, setRequest] = useState<ArchivedContentRequest>({
        sections: [],
        topics: [],
        linkTypes: [],
        query: '',
        from: new Date(new Date().getTime() - 50000 * 60 * 60 * 24 * 7),
        to: new Date(),
    });
    const [contents, setContents] = useState<Array<ContentDto>>([]);
    const [linkTypes, setLinkTypes] = useState<Array<LinkTypeDto>>([]);
    const [topics, setTopics] = useState<Array<TopicDto>>([]);
    const [sections, setSections] = useState<Array<SectionDto>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [notification, setNotification] = useState<string>();

    const params = useParams();
    const navigate = useNavigate();
    const clusterId = parseInt(params.clusterId || '');

    const renderMultiSelect = (
        title: string,
        requestKey: string,
        options: Array<DropdownItemProps>
    ) => {
        return (
            <Select
                options={options}
                fluid
                multiple
                search
                selection
                loading={loading}
                placeholder={title}
                onChange={(_: SyntheticEvent, { value }: DropdownProps) =>
                    setRequest({ ...request, [requestKey]: value as Array<number> })
                }
            />
        );
    };

    const dateFormatter = (date: Date) => {
        const chunk = (input: number) => (input < 10 ? '0' + input : '' + input);
        const value = date instanceof Date ? date : new Date(date);
        return `${value.getFullYear()}-${chunk(value.getMonth() + 1)}-${chunk(value.getDate())}`;
    };

    const generateUrl = (url: string) => {
        if (url) {
            return url.indexOf('http') == 0 ? url : `https://${url}`;
        }
        return '';
    };

    useEffect(() => {
        setLoading(true);
        setNotification('Loading...');
        if (clusterId) {
            contentService
                .searchArchivedContent(clusterId, request)
                .then((response) => {
                    setContents(response.data);
                    setLoading(false);
                    if (!response.data || response.data.length == 0) {
                        setNotification('List of the archived contents is empay.');
                    } else {
                        setNotification(undefined);
                    }
                })
                .catch(() => {
                    setNotification('Something went wrong, please try again.');
                });
        } else {
            navigate('/');
        }
    }, [navigate, clusterId, request]);

    useEffect(() => {
        linkTypeService
            .searchByCluster({ clusterId })
            .then((response) => setLinkTypes(response.data));
    }, [clusterId]);

    useEffect(() => {
        sectionService
            .searchByCluster({ clusterId })
            .then((response) => setSections(response.data));
    }, [clusterId]);

    useEffect(() => {
        clusterService.getClusterTopics(clusterId).then((response) => setTopics(response.data));
    }, [clusterId]);

    return (
        <div className="archive-page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ClusterHeadline clusterId={clusterId} active={ActiveItem.ARCHIVE} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h1 className="page-title">Archive</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 col-12">
                        <div className="page-sub-title">
                            <p>Filter By</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Form className="archive-filter-form">
                            <Form.Group widths="equal">
                                <Form.Field
                                    control={Input}
                                    loading={loading}
                                    value={request.query}
                                    placeholder="Link Title"
                                    onChange={(event: Event) => {
                                        const element = event.currentTarget as HTMLInputElement;
                                        setRequest({ ...request, query: element.value });
                                    }}
                                />
                                <Form.Field>
                                    {renderMultiSelect(
                                        'Topics',
                                        'topics',
                                        topics.map((item) => ({
                                            key: item.id,
                                            value: item.id,
                                            text: item.title,
                                        }))
                                    )}
                                </Form.Field>
                                <Form.Field>
                                    {renderMultiSelect(
                                        'Sections',
                                        'sections',
                                        sections.map((item) => ({
                                            key: item.id,
                                            value: item.id,
                                            text: item.title,
                                        }))
                                    )}
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                {' '}
                                <Form.Field>
                                    {renderMultiSelect(
                                        'Link Types',
                                        'linkTypes',
                                        linkTypes.map((item) => ({
                                            key: item.id,
                                            value: item.id,
                                            text: item.name,
                                        }))
                                    )}
                                </Form.Field>
                                <Form.Field>
                                    <Input
                                        type="date"
                                        placeholder="From"
                                        value={dateFormatter(request.from)}
                                        onChange={(event) =>
                                            setRequest({
                                                ...request,
                                                from: new Date(event.target.value),
                                            })
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input
                                        type="date"
                                        placeholder="To"
                                        value={dateFormatter(request.to)}
                                        onChange={(event) =>
                                            setRequest({
                                                ...request,
                                                to: new Date(event.target.value),
                                            })
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="archive-table">
                            <thead>
                                <tr>
                                    <th>Link Title</th>
                                    <th className="added-date-header">Date Added</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notification && (
                                    <tr className="archive-table-row" key={'0'}>
                                        <td colSpan={3} className="notification">
                                            <Label>{notification}</Label>
                                        </td>
                                    </tr>
                                )}

                                {contents.map((content) => (
                                    <tr className="archive-table-row" key={content.id}>
                                        <td width={'70%'}>
                                            <a
                                                className="archive-content-link"
                                                href={generateUrl(content.link.link)}
                                            >
                                                {content.link.description}
                                            </a>
                                        </td>
                                        <td align="right">{dateFormatter(content.createdAt)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};
export default Archive;
