import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { clusterService } from '../api/http.service';
import { Status } from '../types/Status';
import { ClusterDto } from '../generated/rest';

export const fetchClustersData = createAsyncThunk(
    'clusters/fetchClustersData',
    async (_, thunkAPI) => {
        try {
            const response = await clusterService.getClusters();
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return thunkAPI.rejectWithValue(message);
            }
        }
    }
);

interface ClustersState {
    clustersStatus: Status;
    clusters?: ClusterDto[];
}

const initialState: ClustersState = {
    clustersStatus: Status.IDLE,
};

const clustersSlice = createSlice({
    name: 'clusters',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchClustersData.pending, (state) => {
                state.clustersStatus = Status.LOADING;
            })
            .addCase(fetchClustersData.fulfilled, (state, action) => {
                state.clustersStatus = Status.SUCCEEDED;
                state.clusters = action.payload;
            });
    },
});

const { reducer } = clustersSlice;
export default reducer;
