import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { clusterService, topicService } from '../api/http.service';
import { Status } from '../types/Status';
import { TopicDto } from '../generated/rest';

export const fetchTopicsData = createAsyncThunk('topics/fetchTopicsData', async (_, thunkAPI) => {
    try {
        const response = await topicService.getTopics({ published: true, type: 'TOPIC' });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const fetchClusterTopicsData = createAsyncThunk(
    'topics/fetchClusterTopicsData',
    async (clusterId: number, thunkAPI) => {
        try {
            const response = await clusterService.getClusterTopics(clusterId);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return thunkAPI.rejectWithValue(message);
            }
        }
    }
);

interface TopicsState {
    topicsStatus: Status;
    topics?: TopicDto[];
}

const initialState: TopicsState = {
    topicsStatus: Status.IDLE,
};

const topicsSlice = createSlice({
    name: 'topics',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchClusterTopicsData.pending, (state) => {
                state.topicsStatus = Status.LOADING;
            })
            .addCase(fetchClusterTopicsData.fulfilled, (state, action) => {
                state.topicsStatus = Status.SUCCEEDED;
                state.topics = action.payload;
            })
            .addCase(fetchTopicsData.pending, (state) => {
                state.topicsStatus = Status.LOADING;
            })
            .addCase(fetchTopicsData.fulfilled, (state, action) => {
                state.topicsStatus = Status.SUCCEEDED;
                state.topics = action.payload;
            });
    },
});

const { reducer } = topicsSlice;
export default reducer;
