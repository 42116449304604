import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { UserDto } from '../../generated/rest';
import './UserCard.scss';

const defaultImage = '/assets/images/default-user-icon.png';

const UserCard = ({ user }: { user: UserDto }) => {
    const [url, setUrl] = useState(user.pictureURL || defaultImage);
    const navigate = useNavigate();
    const selectUser = (event: MouseEvent) => {
        navigate(`/profiles/${user.id}`);
        event.stopPropagation();
    };
    const onError = () => setUrl(defaultImage);
    return (
        <div className="user-card" onClick={(event: MouseEvent) => selectUser(event)}>
            <div className="user-card-content">
                <Image onError={onError} src={url} className="picture" centered />
                <div className="user-card-text-content">
                    <span className="curator">Curator</span>
                    <span className="name">{user.name}</span>
                </div>
            </div>
        </div>
    );
};

export default UserCard;
