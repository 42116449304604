import { Button, Card, Header, Image } from 'semantic-ui-react';
import { UserDto } from '../../generated/rest';
import UserCard from '../user-card/UserCard';
import './SiftCard.scss';

export interface SiftCardParams {
    image: string;
    name: string;
    description: string;
    user?: UserDto;
    onButtonClick: () => void;
}

const SiftCard = (props: SiftCardParams) => {
    return (
        <Card centered raised className="sift-card">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <Image className="card-image" src={props.image} />
                </div>
                <div className="col-12 col-lg-6 card-data-side">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-content">
                                <Header className="title " as="h2">
                                    {props.name}
                                </Header>
                                <div className="description">{props.description}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="m-3">
                                {props.user && <UserCard user={props.user} />}
                            </div>
                        </div>
                        <div className="col-4 card-actions">
                            <Button className="m-3" secondary onClick={props.onButtonClick}>
                                VIEW
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default SiftCard;
