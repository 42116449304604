import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { topicService } from '../api/http.service';
import { Status } from '../types/Status';
import { HydratedTopicDto } from '../generated/rest';

export const fetchTopicData = createAsyncThunk(
    'topic/fetchTopicData',
    async ({ currentTopicId }: { currentTopicId: number }, thunkAPI) => {
        try {
            const response = await topicService.getTopic(currentTopicId);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return thunkAPI.rejectWithValue(message);
            }
        }
    }
);

interface TopicState {
    topicStatus: Status;
    topic?: HydratedTopicDto;
}

const initialState: TopicState = {
    topicStatus: Status.IDLE,
};

const topicSlice = createSlice({
    name: 'topic',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopicData.pending, (state) => {
                state.topicStatus = Status.LOADING;
            })
            .addCase(fetchTopicData.fulfilled, (state, action) => {
                state.topicStatus = Status.SUCCEEDED;
                state.topic = action.payload;
            });
    },
});

const { reducer } = topicSlice;
export default reducer;
