import { orderBy } from 'lodash';
import React, { SyntheticEvent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownProps, Loader } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchTopicData } from '../../slices/topicSlice';
import Segment from '../../components/segment/Segment';
import Footer from '../../components/footer/Footer';
import { ActiveItem } from '../../components/headline/Headline';
import { Status } from '../../types/Status';
import { SectionDto } from '../../generated/rest';
import './Topic.scss';
import ClusterHeadline from '../../components/headline/ClusterHeadline';
import { fetchClusterTopicsData } from '../../slices/topicsSlice';

const goalDescription = `
    Our goal is simple. We are here to bring you high quality, human curated content on
    various topics. We sift through 100s of articles, videos, blogs, tweets, books and
    more everyday with one goal in mind, bringing you the best information possible.
`;

const Topic = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { topicStatus, topic } = useAppSelector((state) => state.topic);

    const { topicsStatus, topics } = useAppSelector((state) => state.topics);

    const { clusterId, topicId } = useParams();

    const columnOneSections = topic
        ? topic.sections.filter((section: SectionDto) => section.column === 1)
        : [];
    const columnTwoSections = topic
        ? topic.sections.filter((section: SectionDto) => section.column === 2)
        : [];
    const columnThreeSections = topic
        ? topic.sections.filter((section: SectionDto) => section.column === 3)
        : [];

    const handleTopicPageClick = (slectedTopicId: number) => {
        navigate(`/clusters/${clusterId}/topics/${slectedTopicId}`);
    };

    const createSegmentComponents = (sections: SectionDto[]) => {
        return orderBy(sections, 'position').map((section: SectionDto) => {
            return <Segment key={section.id} section={section} />;
        });
    };

    useEffect(() => {
        if (clusterId) {
            const currentClusterId = Number.parseInt(clusterId);
            dispatch(fetchClusterTopicsData(currentClusterId));
        }
    }, [dispatch, clusterId]);

    useEffect(() => {
        if (topicId) {
            const currentTopicId = Number.parseInt(topicId);
            dispatch(fetchTopicData({ currentTopicId }));
        }
    }, [dispatch, topicId]);

    if (topicStatus === Status.LOADING) {
        return <Loader active inline="centered" />;
    }

    if (topicsStatus === Status.LOADING) {
        return <Loader active inline="centered" />;
    }

    return (
        <div className="topic-page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ClusterHeadline
                            clusterId={Number.parseInt(clusterId ? clusterId : '')}
                            active={ActiveItem.TOPIC}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h1 className="title">{topic?.title}</h1>
                        <p className="goal"> {topic?.description || goalDescription}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="topic-dropdown-container">
                        <Dropdown
                            className="topic-dropdown"
                            placeholder="Change Sub Topics"
                            selection
                            onChange={(_: SyntheticEvent, { value }: DropdownProps) =>
                                handleTopicPageClick(value as number)
                            }
                            options={topics?.map((item) => ({
                                key: item.id,
                                value: item.id,
                                text: item.title,
                            }))}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4">
                        {createSegmentComponents(columnOneSections)}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4">
                        {createSegmentComponents(columnTwoSections)}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4">
                        {createSegmentComponents(columnThreeSections)}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Topic;
