import { Image, Menu } from 'semantic-ui-react';
import './Headline.scss';
import { Link } from 'react-router-dom';

export enum ActiveItem {
    HOME,
    ARCHIVE,
    ABOUT,
    TOPIC,
}

const Headline = () => {
    return (
        <Menu secondary>
            <Link to="/" className="head-line">
                <Image className="logo" src={'/assets/images/logo-sift.png'} />
            </Link>
        </Menu>
    );
};

export default Headline;
