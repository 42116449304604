import { Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Archive from './pages/archive/Archive';
import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import Topic from './pages/topic/Topic';
import './style/App.scss';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about/:clusterId" element={<About />} />
            <Route path="/archive/:clusterId" element={<Archive />} />
            <Route path="clusters/:clusterId/topics/:topicId" element={<Topic />} />
            <Route path="/profiles/:id" element={<Profile />} />
        </Routes>
    );
}

export default App;
