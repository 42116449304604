import { Button, Card, Header, Image } from 'semantic-ui-react';
import './TopicCard.scss';

export interface TopicCardParams {
    image: string;
    name: string;
    description: string;
    onButtonClick: () => void;
}

const TopicCard = (props: TopicCardParams) => {
    return (
        <Card centered raised className="topic-card">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <Image className="card-image" src={props.image} />
                </div>
                <div className="col-12 col-lg-6 card-data-side">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-content">
                                <Header className="title " as="h2">
                                    {props.name}
                                </Header>
                                <div className="description">{props.description}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="card-action">
                            <Button className="m-3" secondary onClick={props.onButtonClick}>
                                VIEW
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default TopicCard;
