import { useEffect } from 'react';
import { Header, Image, Loader } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchClustersData } from '../../slices/clustersSlice';
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { Status } from '../../types/Status';
import { ClusterDto } from '../../generated/rest';
import Headline from '../../components/headline/Headline';
import SiftCard from '../../components/sift-card/SiftCard';
import './Home.scss';

const goalDescripiton = `
    Our goal is simple. We are here to bring you high quality, human
    curated content on various topics. We sift through 1000s of articles,
    videos, blogs, tweets, books and more everyday with one goal in
    mind, bringing you the best information possible.
`;

const defaultClusterDescription = `
    Many people also have their own barometers for what makes a
    cute dog. books and more everyday with one goal in mind,
    bringing you the best information possible. books and more
    everyday with one goal in mind, bringing you the best
    information possible.
`;

function Home() {
    const dispatch = useAppDispatch();

    const { clustersStatus, clusters } = useAppSelector((state) => state.clusters);

    useEffect(() => {
        if (clustersStatus === Status.IDLE) {
            dispatch(fetchClustersData());
        }
    }, [clustersStatus, dispatch]);

    const navigate = useNavigate();

    const handleClusterPageClick = (clusterId: number) => {
        navigate(`/about/${clusterId}`);
    };

    if (clustersStatus === Status.LOADING) {
        return <Loader active inline="centered" />;
    }

    const renderHeadLine = () => (
        <div className="row align-items-center head-line">
            <div className="col-12 col-lg-6 order-md-2">
                <Image centered src={'/assets/images/homepage-icon.png'} />
            </div>
            <div className="col-12 col-lg-6">
                <Header as="h2" className="welcome">
                    Welcome to Sift!
                </Header>
                <p className="goal">{goalDescripiton}</p>
            </div>
        </div>
    );

    const renderCluster = (cluster: ClusterDto) => {
        const user = cluster.users && cluster.users.length > 0 ? cluster.users[0] : undefined;
        return (
            <SiftCard
                user={user}
                name={cluster.name}
                description={cluster.description || defaultClusterDescription}
                image={cluster.pictureUrl || '/assets/images/cluster-card.png'}
                onButtonClick={() => handleClusterPageClick(cluster.id)}
            />
        );
    };

    const renderClusters = () => (
        <div className="row clusters">
            {clusters &&
                clusters.map((cluster) => (
                    <div key={cluster.id} className="col-12 mt-4">
                        {renderCluster(cluster)}
                    </div>
                ))}
        </div>
    );

    return (
        <div className="home-page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Headline />
                    </div>
                </div>
                {renderHeadLine()}
            </div>
            <h1 className="clusters-title">Topics</h1>

            <div className="container">
                {renderClusters()}
                <div className="row">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Home;
