import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { userService } from '../api/http.service';
import { Status } from '../types/Status';
import { UserDto } from '../generated/rest';

export const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async ({ userId }: { userId: number }, thunkAPI) => {
        try {
            const response = await userService.findUser(userId);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return thunkAPI.rejectWithValue(message);
            }
        }
    }
);

interface UserState {
    status: Status;
    user?: UserDto;
}

const initialState: UserState = {
    status: Status.IDLE,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.status = Status.LOADING;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.status = Status.SUCCEEDED;
                state.user = action.payload;
            });
    },
});

const { reducer } = userSlice;
export default reducer;
