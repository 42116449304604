import { configureStore } from '@reduxjs/toolkit';
import topicReducer from './slices/topicSlice';
import topicsReducer from './slices/topicsSlice';
import userReducer from './slices/userSlice';
import clustersReducer from './slices/clustersSlice';
import clusterReducer from './slices/clusterSlice';

const reducer = {
    topic: topicReducer,
    topics: topicsReducer,
    clusters: clustersReducer,
    cluster: clusterReducer,
    user: userReducer,
};

export const store = configureStore({
    reducer: reducer,
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
