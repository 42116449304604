import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { clusterService } from '../api/http.service';
import { ClusterDto } from '../generated/rest';
import { Status } from '../types/Status';

export const fetchClusterData = createAsyncThunk(
    'cluster/fetchClusterData',
    async (clusterId: number, thunkAPI) => {
        try {
            const response = await clusterService.getCluster(clusterId);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                return thunkAPI.rejectWithValue(message);
            }
        }
    }
);

interface ClusterState {
    clusterStatus: Status;
    cluster?: ClusterDto;
}

const initialState: ClusterState = {
    clusterStatus: Status.IDLE,
};

const clusterSlice = createSlice({
    name: 'cluster',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClusterData.pending, (state) => {
                state.clusterStatus = Status.LOADING;
            })
            .addCase(fetchClusterData.fulfilled, (state, action) => {
                state.clusterStatus = Status.SUCCEEDED;
                state.cluster = action.payload;
            });
    },
});

const { reducer } = clusterSlice;
export default reducer;
