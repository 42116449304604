import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import Headline, { ActiveItem } from '../../components/headline/Headline';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchUserData } from '../../slices/userSlice';
import './Profile.scss';
import ProfileView from './ProfileView';

const Profile = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { user } = useAppSelector((state) => state.user);

    useEffect(() => {
        if (id) {
            const userId = Number.parseInt(id);
            dispatch(fetchUserData({ userId }));
        }
    }, [dispatch, id]);

    return (
        <div className="profile-page">
            <div className="container">
                <Headline />
                <div className="profile">
                    <div className="content">
                        {user ? <ProfileView user={user} /> : <Loader active inline="centered" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
