import { Config } from '../config/Config';
import {
    AxiosCommonClusterControllerClient,
    AxiosCommonContentsControllerClient,
    AxiosCommonLinkTypeControllerClient,
    AxiosCommonSectionsControllerClient,
    AxiosCommonTopicsControllerClient,
    AxiosCommonUsersControllerClient,
} from '../generated/rest';

const clusterService = new AxiosCommonClusterControllerClient(Config.BASE_API_URL);
const topicService = new AxiosCommonTopicsControllerClient(Config.BASE_API_URL);
const userService = new AxiosCommonUsersControllerClient(Config.BASE_API_URL);
const contentService = new AxiosCommonContentsControllerClient(Config.BASE_API_URL);
const sectionService = new AxiosCommonSectionsControllerClient(Config.BASE_API_URL);
const linkTypeService = new AxiosCommonLinkTypeControllerClient(Config.BASE_API_URL);

export {
    clusterService,
    topicService,
    userService,
    contentService,
    sectionService,
    linkTypeService,
};
