/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.36.1070 on 2022-09-02 12:02:12.

export interface ErrorResponse {
    error: SiftError;
    data: any;
    message: string;
    time: Date;
}

export interface JwtResponse {
    accessToken: string;
    user: UserDto;
}

export interface LoginRequest {
    username: string;
    password: string;
}

export interface PasswordChangeRequest {
    previousPassword: string;
    newPassword: string;
}

export interface SignupRequest {
    username: string;
    role: string[];
    password: string;
}

export interface ClusterDto {
    id: number;
    name: string;
    pictureUrl: string;
    published: boolean;
    description: string;
    users: UserDto[];
}

export interface ClusterRequest {
    name: string;
    published: boolean;
    userId: number;
}

export interface ArchivedContentRequest {
    query: string;
    topics: number[];
    sections: number[];
    linkTypes: number[];
    from: Date;
    to: Date;
}

export interface ContentDto {
    id: number;
    link: LinkDto;
    title: TitleDto;
    type: EContentType;
    position: number;
    createdAt: Date;
}

export interface ContentPositionRequest {
    contentId: number;
    position: number;
    priority: number;
}

export interface ContentRequest {
    title: string;
    contentType: EContentType;
    position: number;
    link: string;
    description: string;
    free: boolean;
    linkTypes: number[];
}

export interface HeadlineDto {
    id: number;
    title: string;
    link: string;
    description: string;
    free: boolean;
    type: string;
    active: boolean;
}

export interface LinkDto {
    id: number;
    link: string;
    description: string;
    free: boolean;
    linkTypes: LinkTypeDto[];
}

export interface TitleDto {
    id: number;
    title: string;
}

export interface LinkTypeDto {
    id: number;
    name: string;
    clusterId: number;
}

export interface LinkTypeRequest {
    name: string;
    clusterId: number;
}

export interface RoleDto {
    name: ERole;
}

export interface RecalculatePositionsRequest {
    sectionId: number;
    position: number;
    column: number;
    priority: number;
}

export interface SectionDto {
    id: number;
    title: string;
    column: number;
    position: number;
    contents: ContentDto[];
}

export interface SectionRequest {
    title: string;
    column: number;
    position: number;
}

export interface HydratedTopicDto {
    id: number;
    title: string;
    description: string;
    type: ETopicType;
    clusterId: number;
    sections: SectionDto[];
}

export interface SectionPositionRequest {
    sectionId: number;
    position: number;
}

export interface TopicDto {
    id: number;
    title: string;
    position: number;
    pictureURL: string;
    description: string;
    type: ETopicType;
    published: boolean;
    clusterId: number;
    users: UserDto[];
}

export interface TopicRequest {
    title: string;
    type: ETopicType;
    published: boolean;
    position: number;
    userId: number;
    clusterId: number;
}

export interface UserDto {
    id: number;
    name: string;
    username: string;
    email: string;
    background: string;
    pictureURL: string;
    roles: ERole[];
}

export interface UserRequest {
    name: string;
    username: string;
    email: string;
    background: string;
}

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export class CommonUsersControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/common/users/{userId}
     * Java method: com.sift.api.resources.users.controllers.CommonUsersController.findUser
     */
    findUser(userId: number, options?: O): RestResponse<UserDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/users/${userId}`, options: options });
    }
}

export class CommonSectionsControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/common/sections/cluster
     * Java method: com.sift.api.resources.sections.controllers.CommonSectionsController.searchByCluster
     */
    searchByCluster(queryParams: { clusterId: number; }, options?: O): RestResponse<SectionDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/sections/cluster`, queryParams: queryParams, options: options });
    }
}

export class CommonTopicsControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/common/topics
     * Java method: com.sift.api.resources.topics.controllers.CommonTopicsController.getTopics
     */
    getTopics(queryParams?: { published?: boolean; type?: ETopicType; }, options?: O): RestResponse<TopicDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/topics`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/common/topics/{id}
     * Java method: com.sift.api.resources.topics.controllers.CommonTopicsController.getTopic
     */
    getTopic(id: number, options?: O): RestResponse<HydratedTopicDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/topics/${id}`, options: options });
    }
}

export class CommonLinkTypeControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/common/link-types
     * Java method: com.sift.api.resources.linktypes.controllers.CommonLinkTypeController.search
     */
    search(options?: O): RestResponse<LinkTypeDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/link-types`, options: options });
    }

    /**
     * HTTP GET /api/common/link-types/cluster
     * Java method: com.sift.api.resources.linktypes.controllers.CommonLinkTypeController.searchByCluster
     */
    searchByCluster(queryParams: { clusterId: number; }, options?: O): RestResponse<LinkTypeDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/link-types/cluster`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/common/link-types/{id}
     * Java method: com.sift.api.resources.linktypes.controllers.CommonLinkTypeController.findById
     */
    findById(id: number, options?: O): RestResponse<LinkTypeDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/link-types/${id}`, options: options });
    }
}

export class CommonContentsControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP POST /api/common/clusters/{clusterId}/contents/archived
     * Java method: com.sift.api.resources.contents.controllers.CommonContentsController.searchArchivedContent
     */
    searchArchivedContent(clusterId: number, request: ArchivedContentRequest, options?: O): RestResponse<ContentDto[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/common/clusters/${clusterId}/contents/archived`, data: request, options: options });
    }
}

export class CommonClusterControllerClient<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /api/common/clusters
     * Java method: com.sift.api.resources.clusters.controllers.CommonClusterController.getClusters
     */
    getClusters(options?: O): RestResponse<ClusterDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/clusters`, options: options });
    }

    /**
     * HTTP GET /api/common/clusters/{id}
     * Java method: com.sift.api.resources.clusters.controllers.CommonClusterController.getCluster
     */
    getCluster(id: number, options?: O): RestResponse<ClusterDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/clusters/${id}`, options: options });
    }

    /**
     * HTTP GET /api/common/clusters/{id}/topics
     * Java method: com.sift.api.resources.clusters.controllers.CommonClusterController.getClusterTopics
     */
    getClusterTopics(id: number, options?: O): RestResponse<TopicDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/common/clusters/${id}/topics`, options: options });
    }
}

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export type SiftError = "RESOURCE_NOT_FOUND" | "BAD_REQUEST" | "USER_NOT_FOUND" | "TOPIC_NOT_FOUND" | "CLUSTER_NOT_FOUND" | "SECTION_NOT_FOUND" | "CONTENT_NOT_FOUND" | "ROLE_NOT_FOUND" | "WRONG_CREDENTIALS" | "WRONG_TOKEN" | "WRONG_USER" | "USER_NOT_AUTHENTICATED" | "EMAIL_ALREADY_USED" | "PERMISSIONS_ERROR" | "INVALID_TOPIC_ID" | "INVALID_SECTION_ID" | "UNKNOWN_ERROR";

export type EContentType = "LINK" | "TITLE" | "PICTURE" | "LINE_BREAK";

export type ERole = "ROLE_USER" | "ROLE_CURATOR" | "ROLE_ADMIN";

export type ETopicType = "TOPIC" | "SUB_TOPIC";

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AxiosClientExtension' extension

import axios from "axios";
import * as Axios from "axios";

declare module "axios" {
    export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
        data: R;
    }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {

    constructor(private axios: Axios.AxiosInstance) {
    }

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: Axios.AxiosRequestConfig; }): RestResponse<R> {
        function assign(target: any, source?: any) {
            if (source != undefined) {
                for (const key in source) {
                    if (source.hasOwnProperty(key)) {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }

        const config: Axios.AxiosRequestConfig = {};
        config.method = requestConfig.method as typeof config.method;  // `string` in axios 0.16.0, `Method` in axios 0.19.0
        config.url = requestConfig.url;
        config.params = requestConfig.queryParams;
        config.data = requestConfig.data;
        assign(config, requestConfig.options);
        const copyFn = requestConfig.copyFn;

        const axiosResponse = this.axios.request(config);
        return axiosResponse.then(axiosResponse => {
            if (copyFn && axiosResponse.data) {
                (axiosResponse as any).originalData = axiosResponse.data;
                axiosResponse.data = copyFn(axiosResponse.data);
            }
            return axiosResponse;
        });
    }
}

export class AxiosCommonUsersControllerClient extends CommonUsersControllerClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}

export class AxiosCommonSectionsControllerClient extends CommonSectionsControllerClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}

export class AxiosCommonTopicsControllerClient extends CommonTopicsControllerClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}

export class AxiosCommonLinkTypeControllerClient extends CommonLinkTypeControllerClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}

export class AxiosCommonContentsControllerClient extends CommonContentsControllerClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}

export class AxiosCommonClusterControllerClient extends CommonClusterControllerClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
